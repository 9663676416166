// Core
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";
import InnerRichText from "../inner-rich-text";
// Third Party Components
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

class RichText extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <strong>{text}</strong>,
        [MARKS.ITALIC]: text => <em>{text}</em>,
        [MARKS.UNDERLINE]: text => <u>{text}</u>,
      },
      renderNode: {
        [BLOCKS.TABLE]: (node, children) => (
          <table className="w-full border-black border-2">
            <tbody>{children}</tbody>
          </table>
        ),
        [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th className="border-black border-2">{children}</th>,
        [BLOCKS.TABLE_ROW]: (node, children) => <tr className="border-black border-2">{children}</tr>,
        [BLOCKS.TABLE_CELL]: (node, children) => <td className="border-black border-2">{children}</td>,
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const { gatsbyImageData, title } = this.props.references.find(x => x.contentful_id === node.data.target.sys.id);
          return (
            <div className={this.props.embeddedAssetAlignment ? "flex items-center justify-center md:justify-start w-full" : "flex items-center justify-center w-full"}>
              <GatsbyImage
                alt={title}
                image={gatsbyImageData}
              />
            </div>
            
          );
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const data = this.props.references.find(x => x.contentful_id === node.data.target.sys.id);
          const { buttonText, buttonLink, imagePlacement, images, richtext, gifs, gifPlacement } = data;
          return (
            <>
              {buttonText && buttonLink && 
                <a
                  className="button button--secondary"
                  href={buttonLink}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {buttonText}
                </a>
              }
              {richtext && images && (images.length === 1) &&
                <div className="my-4 flex flex-col md:flex-row gap-y-2 gap-x-0 md:gap-x-4 items-center">
                  <div className={["w-full md:w-1/2", imagePlacement === "Left" ? "order-2" : "order-1"].join(" ")}>
                    <InnerRichText json={JSON.parse(richtext.raw)}/>
                  </div>
                  <GatsbyImage alt={images[0].title} className={["w-full md:w-1/2 shrink-0", imagePlacement === "Left" ? "order-1" : "order-2"].join(" ")} image={getImage(images[0].gatsbyImageData)}/>
                </div>
              }
              {richtext && gifs && gifs.length === 1 &&
                <div className="my-4 flex flex-col md:flex-row gap-y-2 gap-x-0 md:gap-x-4 items-center">
                  <div className={["w-full md:w-1/2", gifPlacement === "Left" ? "order-2" : "order-1"].join(" ")}>
                    <InnerRichText json={JSON.parse(richtext.raw)}/>
                  </div>
                  
                  <video autoPlay className={["w-full md:w-1/2", gifPlacement === "Left" ? "order-1" : "order-2"].join(" ")} loop muted playsInline>
                    <source src={gifs[0].url} type="video/mp4" />
                  </video>
                 
                </div>
              }
              {images && ((images.length % 2) === 0) && !richtext &&
                <div className="my-4 flex flex-col gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4  md:flex-row items-center">
                  {images.map((image, index) => {
                    return (
                      <GatsbyImage alt={image.title} className="w-full md:w-1/2" image={getImage(image.gatsbyImageData)} key={index}/>
                    );
                  })}
                </div>
              }
              {images && (images.length === 3) && !richtext &&
                <div className="my-4 grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4 items-center">
                  {images.map((image, index) => {
                    return (
                      <GatsbyImage alt={image.title} image={getImage(image.gatsbyImageData)} key={index}/>
                    );
                  })}
                </div>
              }
              {gifs && ((gifs.length % 2) === 1) && !richtext &&
                <div className="my-4 grid grid-cols-1 md:grid-cols-3 gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4 items-center">
                  {gifs.map((gif, index) => {
                    return (
                      <video autoPlay key={index} loop muted playsInline>
                        <source src={gif.url} type="video/mp4"/>
                      </video>
                    );
                  })}
                </div>
              }
              {gifs && ((gifs.length % 2) === 0) && !richtext &&
                <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-0 md:gap-x-4 items-center">
                  {gifs.map((gif, index) => {
                    return (
                      <video autoPlay key={index} loop muted playsInline>
                        <source src={gif.url} type="video/mp4"/>
                      </video>
                    );
                  })}
                </div>
              }
            </>
          );
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          const { url, videoName } = this.props.references.find(x => x.contentful_id === node.data.target.sys.id);
          const video_id = url.split("/").slice(-1)[0].split("=").slice(-1)[0];
          return (
            <div className="w-full">
              <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="w-full h-[315px]" frameBorder="0" src={`https://www.youtube.com/embed/${video_id}`} title={videoName}></iframe>
            </div> 
          );
        },
        [BLOCKS.HEADING_1]: (node, children) => { 
          return (
            <h1 className={"font-poppins" || ""}>{children}</h1>
          );},
        [BLOCKS.HEADING_2]: (node, children) => <h2 className={"font-poppins" || ""}>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3 className={"font-poppins" || ""}>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4 className={"font-poppins" || ""}>{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5 className={"font-poppins" || ""}>{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6 className={"font-poppins" || ""}>{children}</h6>,
        [BLOCKS.UL_LIST]: (node, children) => (
          <ul className="list-disc">{children}</ul>
        ),
        [BLOCKS.OL_LIST]: (node, children) => (
          <ol>{children}</ol>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p className={"font-poppins text-[16px]" || ""}>{children}</p>,
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a
              href={node.data.uri}
              rel="noreferrer noopener"
              target="_blank"
            >
              {children}
            </a>
          );
        },
      },
      renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
    };
    return documentToReactComponents(this.props.json, options);
  }
}

RichText.propTypes = {
  headingClassNames: PropTypes.string,
  json: PropTypes.object.isRequired,
  references: PropTypes.array,
  embeddedAssetAlignment: PropTypes.string,
};

export default RichText;
