// Core
import PropTypes from "prop-types";
import React from "react";
// Third Party Components
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

class InnerRichText extends React.Component {
  render() {
    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <strong>{text}</strong>,
        [MARKS.ITALIC]: text => <em>{text}</em>,
        [MARKS.UNDERLINE]: text => <u>{text}</u>,
      },
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => <h1 className={"font-poppins" || ""}>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => <h2 className={"font-poppins" || ""}>{children}</h2>,
        [BLOCKS.HEADING_3]: (node, children) => <h3 className={"font-poppins" || ""}>{children}</h3>,
        [BLOCKS.HEADING_4]: (node, children) => <h4 className={"font-poppins" || ""}>{children}</h4>,
        [BLOCKS.HEADING_5]: (node, children) => <h5 className={"font-poppins" || ""}>{children}</h5>,
        [BLOCKS.HEADING_6]: (node, children) => <h6 className={"font-poppins" || ""}>{children}</h6>,
        [BLOCKS.PARAGRAPH]: (node, children) => <p className={"font-poppins text-[16px]" || ""}>{children}</p>,
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a
              href={node.data.uri}
              rel="noreferrer noopener"
              target="_blank"
            >
              {children}
            </a>
          );
        },
      },
      renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment];
        }, []);
      },
    };
    return documentToReactComponents(this.props.json, options);
  }
}

InnerRichText.propTypes = {
  headingClassNames: PropTypes.string,
  json: PropTypes.object.isRequired,
  references: PropTypes.array,
};

export default InnerRichText;
