// Core
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from 'react';
import HuFormV2 from "../../hireUs/huForm-v2";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ReactTidyCal } from "react-tidycal";

import {
  RichText,
} from "../../../components/";

const Content = ({ data })=> {  
    return (
      <div>
        {!data.calendly &&
        <GatsbyImage
          alt={data.image?.title}
          className="max-w-full max-h-[425px] object-contain object-center"
          image={data.image?.gatsbyImageData}
        />}
        
        {!data.image.gatsbyImageData && data.image.file.url &&
        <video className="w-full max-h-[400px]" controls>
          <source src={data.image.file.url} type="video/mp4"/>
        Your browser does not support the video tag.
        </video>}
        
        <div className="bg-dark-blue px-6 py-10">
          <div className="mb-6 relative w-full mx-auto">
            <div className="absolute left-[-25px] lg:left-[-30px] top-0 bg-orange md:rounded-r-full blog-category-container w-[120%] md:w-full h-full z-[0]"></div>
            <div className="cContainer relative z-[2] flex items-center justify-center md:justify-start">
              <p className="text-white mb-0 text-[16px] font-poppins font-medium text-format">{data.category}</p>
            </div>
          </div>
          <div className="cContainer">
            <div className="flex flex-row items-center justify-center md:justify-start">
              <h1 className="text-white text-format font-montserrat font-bold text-[18px] sm:text-[24px] md:text-[36px]">{data.title}</h1>
            </div>

            <div className="flex flex-row items-center justify-center md:justify-start mt-10">
              <h6 className="text-white text-format font-poppins font-normal text-[16px]">{data.author} |</h6>
              <h6 className="text-orange text-format font-poppins font-normal text-[16px]">&nbsp;{data.date}</h6>
            </div>
            
          </div>
        </div>

        <div className="px-6 py-16 relative">
          <div className="hidden lg:block absolute w-full viewport-test right-0 top-[-50px]">
            <div className="bg-orange w-full h-[285px] flex justify-center flex-col items-center px-4 py-6">
              <h4 className="text-white text-center font-montserrat !text-[26px] font-bold"> Get in touch with us to scale your business</h4>
              {data.form && <AnchorLink className="button button--primary text-[24px] mt-4 font-bold font-montserrat" to={`${data.path}#blog-sign-up`}>Let&apos;s Chat</AnchorLink>}
              {data.calendly &&
              <AnchorLink className="button button--primary text-[24px] mt-4 font-bold font-montserrat" to={`${data.path}#cal-sign-up`}>Let&apos;s Chat</AnchorLink>}
            </div>
          </div>
          <div className="cContainer grid grid-cols-5 items-start gap-x-8">
            <div className="col-span-5 lg:col-span-4 font-poppins font-normal text-[18px] md:text-[23px]">
              <RichText json={JSON.parse(data.body.raw)} references={data.body.references} />
            </div>
          </div>
        </div>
        <div className="flex lg:hidden bg-orange w-full flex-col items-center px-6 py-12">
          <h4 className="text-white text-center font-montserrat !text-[30px] font-bold mb-4"> Get in touch with us to scale your business</h4>
          {data.form && <AnchorLink className="button button--primary font-montserrat" to={`${data.path}#blog-sign-up`}>Let&apos;s Chat</AnchorLink>}
          {data.calendly && <AnchorLink className="button button--primary font-montserrat" to={`${data.path}#cal-sign-up`}>Let&apos;s Chat</AnchorLink>}
        </div>

        {data.calendly &&
        <div id="cal-sign-up" className="w-full max-w-[1440px] ml-auto mr-auto mb-[3rem]">
           <ReactTidyCal path="mattswulinski/ffg-sales" />
        </div>
          
        }

        {/* sign up */}
        {data.form &&
        <div className="bg-dark-blue"id="blog-sign-up">
          <div className="px-6 py-6">
            <div className="cContainer">
              <p className="font-montserrat text-[18px] font-semibold text-white mb-4">Like what you see?</p>
              <h4 className="font-montserrat !text-[18px] sm:!text-[24px] md:!text-[36px] font-bold text-white mb-6">Let&apos;s work together!</h4>
              <HuFormV2/>
            </div>
          </div>
        </div>}
        
      </div>
    );
}

export default Content;
